var exports = {};
exports = {
  A: {
    A: {
      J: 0.0131217,
      D: 0.00621152,
      E: 0.0349975,
      F: 0.0699949,
      A: 0.0209985,
      B: 0.601956,
      rB: 0.009298
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "rB", "J", "D", "E", "F", "A", "B", "", "", ""],
    E: "IE",
    F: {
      rB: 962323200,
      J: 998870400,
      D: 1161129600,
      E: 1237420800,
      F: 1300060800,
      A: 1346716800,
      B: 1381968000
    }
  },
  B: {
    A: {
      C: 0.004257,
      K: 0.004267,
      L: 0.004268,
      G: 0.004257,
      M: 0.004257,
      N: 0.008514,
      O: 0.025542,
      P: 0,
      Q: 0.004298,
      R: 0.00944,
      S: 0.004043,
      V: 0.004257,
      W: 0.008514,
      X: 0.004257,
      Y: 0.004257,
      Z: 0.004318,
      a: 0.008514,
      b: 0.004257,
      c: 0.008514,
      d: 0.012771,
      e: 0.012771,
      f: 0.012771,
      g: 0.063855,
      T: 1.08554,
      h: 2.81388,
      H: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "C", "K", "L", "G", "M", "N", "O", "P", "Q", "R", "S", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "T", "h", "H", "", "", ""],
    E: "Edge",
    F: {
      C: 1438128000,
      K: 1447286400,
      L: 1470096000,
      G: 1491868800,
      M: 1508198400,
      N: 1525046400,
      O: 1542067200,
      P: 1579046400,
      Q: 1581033600,
      R: 1586736000,
      S: 1590019200,
      V: 1594857600,
      W: 1598486400,
      X: 1602201600,
      Y: 1605830400,
      Z: 1611360000,
      a: 1614816000,
      b: 1618358400,
      c: 1622073600,
      d: 1626912000,
      e: 1630627200,
      f: 1632441600,
      g: 1634774400,
      T: 1637539200,
      h: 1641427200,
      H: 1643932800
    },
    D: {
      C: "ms",
      K: "ms",
      L: "ms",
      G: "ms",
      M: "ms",
      N: "ms",
      O: "ms"
    }
  },
  C: {
    A: {
      "0": 0.004257,
      "1": 0.004783,
      "2": 0.004271,
      "3": 0.004783,
      "4": 0.00487,
      "5": 0.005029,
      "6": 0.0047,
      "7": 0.038313,
      "8": 0.008514,
      "9": 0.004356,
      sB: 0.004257,
      gB: 0.004271,
      I: 0.017028,
      i: 0.004879,
      J: 0.020136,
      D: 0.005725,
      E: 0.004525,
      F: 0.00533,
      A: 0.004283,
      B: 0.004318,
      C: 0.004471,
      K: 0.004486,
      L: 0.00453,
      G: 0.004257,
      M: 0.004417,
      N: 0.004425,
      O: 0.004257,
      j: 0.004443,
      k: 0.004283,
      l: 0.004257,
      m: 0.013698,
      n: 0.004257,
      o: 0.008786,
      p: 0.008514,
      q: 0.004317,
      r: 0.004393,
      s: 0.004418,
      t: 0.008834,
      u: 0.004257,
      v: 0.008928,
      w: 0.004471,
      x: 0.009284,
      y: 0.004707,
      z: 0.009076,
      AB: 0.004525,
      BB: 0.004293,
      CB: 0.004257,
      DB: 0.004538,
      EB: 0.008282,
      FB: 0.008514,
      GB: 0.063855,
      HB: 0.004335,
      IB: 0.008586,
      JB: 0.012771,
      KB: 0.008514,
      LB: 0.004425,
      MB: 0.004356,
      hB: 0.004257,
      NB: 0.008514,
      iB: 0.004356,
      OB: 0.004425,
      PB: 0.004257,
      U: 0.00415,
      QB: 0.004267,
      RB: 0.008712,
      SB: 0.004267,
      TB: 0.008514,
      UB: 0.00415,
      VB: 0.004293,
      WB: 0.004425,
      XB: 0.008514,
      YB: 0.00415,
      ZB: 0.00415,
      aB: 0.004318,
      bB: 0.004356,
      cB: 0.004257,
      dB: 0.068112,
      P: 0.008514,
      Q: 0.004257,
      R: 0.012771,
      jB: 0.004257,
      S: 0.004257,
      V: 0.017028,
      W: 0.004268,
      X: 0.004257,
      Y: 0.012771,
      Z: 0.012771,
      a: 0.012771,
      b: 0.029799,
      c: 0.097911,
      d: 0.012771,
      e: 0.017028,
      f: 0.046827,
      g: 1.41758,
      T: 1.77517,
      h: 0.017028,
      H: 0,
      kB: 0,
      tB: 0.008786,
      uB: 0.00487
    },
    B: "moz",
    C: ["sB", "gB", "tB", "uB", "I", "i", "J", "D", "E", "F", "A", "B", "C", "K", "L", "G", "M", "N", "O", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "hB", "NB", "iB", "OB", "PB", "U", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "P", "Q", "R", "jB", "S", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "T", "h", "H", "kB", ""],
    E: "Firefox",
    F: {
      "0": 1424736000,
      "1": 1428278400,
      "2": 1431475200,
      "3": 1435881600,
      "4": 1439251200,
      "5": 1442880000,
      "6": 1446508800,
      "7": 1450137600,
      "8": 1453852800,
      "9": 1457395200,
      sB: 1161648000,
      gB: 1213660800,
      tB: 1246320000,
      uB: 1264032000,
      I: 1300752000,
      i: 1308614400,
      J: 1313452800,
      D: 1317081600,
      E: 1317081600,
      F: 1320710400,
      A: 1324339200,
      B: 1327968000,
      C: 1331596800,
      K: 1335225600,
      L: 1338854400,
      G: 1342483200,
      M: 1346112000,
      N: 1349740800,
      O: 1353628800,
      j: 1357603200,
      k: 1361232000,
      l: 1364860800,
      m: 1368489600,
      n: 1372118400,
      o: 1375747200,
      p: 1379376000,
      q: 1386633600,
      r: 1391472000,
      s: 1395100800,
      t: 1398729600,
      u: 1402358400,
      v: 1405987200,
      w: 1409616000,
      x: 1413244800,
      y: 1417392000,
      z: 1421107200,
      AB: 1461628800,
      BB: 1465257600,
      CB: 1470096000,
      DB: 1474329600,
      EB: 1479168000,
      FB: 1485216000,
      GB: 1488844800,
      HB: 1492560000,
      IB: 1497312000,
      JB: 1502150400,
      KB: 1506556800,
      LB: 1510617600,
      MB: 1516665600,
      hB: 1520985600,
      NB: 1525824000,
      iB: 1529971200,
      OB: 1536105600,
      PB: 1540252800,
      U: 1544486400,
      QB: 1548720000,
      RB: 1552953600,
      SB: 1558396800,
      TB: 1562630400,
      UB: 1567468800,
      VB: 1571788800,
      WB: 1575331200,
      XB: 1578355200,
      YB: 1581379200,
      ZB: 1583798400,
      aB: 1586304000,
      bB: 1588636800,
      cB: 1591056000,
      dB: 1593475200,
      P: 1595894400,
      Q: 1598313600,
      R: 1600732800,
      jB: 1603152000,
      S: 1605571200,
      V: 1607990400,
      W: 1611619200,
      X: 1614038400,
      Y: 1616457600,
      Z: 1618790400,
      a: 1622505600,
      b: 1626134400,
      c: 1628553600,
      d: 1630972800,
      e: 1633392000,
      f: 1635811200,
      g: 1638835200,
      T: 1641859200,
      h: 1644364800,
      H: null,
      kB: null
    }
  },
  D: {
    A: {
      "0": 0.004335,
      "1": 0.004464,
      "2": 0.025542,
      "3": 0.004464,
      "4": 0.012771,
      "5": 0.0236,
      "6": 0.004293,
      "7": 0.008514,
      "8": 0.004465,
      "9": 0.004642,
      I: 0.004706,
      i: 0.004879,
      J: 0.004879,
      D: 0.005591,
      E: 0.005591,
      F: 0.005591,
      A: 0.004534,
      B: 0.004464,
      C: 0.010424,
      K: 0.0083,
      L: 0.004706,
      G: 0.015087,
      M: 0.004393,
      N: 0.004393,
      O: 0.008652,
      j: 0.004257,
      k: 0.004393,
      l: 0.004317,
      m: 0.008514,
      n: 0.008786,
      o: 0.012771,
      p: 0.004461,
      q: 0.004141,
      r: 0.004326,
      s: 0.0047,
      t: 0.004538,
      u: 0.004257,
      v: 0.008596,
      w: 0.004566,
      x: 0.008514,
      y: 0.008514,
      z: 0.012771,
      AB: 0.004891,
      BB: 0.012771,
      CB: 0.021285,
      DB: 0.072369,
      EB: 0.004293,
      FB: 0.004257,
      GB: 0.004257,
      HB: 0.012771,
      IB: 0.008514,
      JB: 0.008514,
      KB: 0.051084,
      LB: 0.008514,
      MB: 0.008514,
      hB: 0.008514,
      NB: 0.012771,
      iB: 0.004257,
      OB: 0.008514,
      PB: 0.012771,
      U: 0.021285,
      QB: 0.012771,
      RB: 0.021285,
      SB: 0.012771,
      TB: 0.012771,
      UB: 0.055341,
      VB: 0.046827,
      WB: 0.017028,
      XB: 0.059598,
      YB: 0.008514,
      ZB: 0.029799,
      aB: 0.063855,
      bB: 0.068112,
      cB: 0.021285,
      dB: 0.046827,
      P: 0.183051,
      Q: 0.068112,
      R: 0.04257,
      S: 0.089397,
      V: 0.072369,
      W: 0.076626,
      X: 0.072369,
      Y: 0.148995,
      Z: 0.04257,
      a: 0.059598,
      b: 0.055341,
      c: 0.131967,
      d: 0.174537,
      e: 0.34056,
      f: 0.29799,
      g: 0.229878,
      T: 7.72646,
      h: 15.687,
      H: 0.017028,
      kB: 0.012771,
      vB: 0.012771,
      wB: 0
    },
    B: "webkit",
    C: ["", "", "", "", "I", "i", "J", "D", "E", "F", "A", "B", "C", "K", "L", "G", "M", "N", "O", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "hB", "NB", "iB", "OB", "PB", "U", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "P", "Q", "R", "S", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "T", "h", "H", "kB", "vB", "wB"],
    E: "Chrome",
    F: {
      "0": 1405468800,
      "1": 1409011200,
      "2": 1412640000,
      "3": 1416268800,
      "4": 1421798400,
      "5": 1425513600,
      "6": 1429401600,
      "7": 1432080000,
      "8": 1437523200,
      "9": 1441152000,
      I: 1264377600,
      i: 1274745600,
      J: 1283385600,
      D: 1287619200,
      E: 1291248000,
      F: 1296777600,
      A: 1299542400,
      B: 1303862400,
      C: 1307404800,
      K: 1312243200,
      L: 1316131200,
      G: 1316131200,
      M: 1319500800,
      N: 1323734400,
      O: 1328659200,
      j: 1332892800,
      k: 1337040000,
      l: 1340668800,
      m: 1343692800,
      n: 1348531200,
      o: 1352246400,
      p: 1357862400,
      q: 1361404800,
      r: 1364428800,
      s: 1369094400,
      t: 1374105600,
      u: 1376956800,
      v: 1384214400,
      w: 1389657600,
      x: 1392940800,
      y: 1397001600,
      z: 1400544000,
      AB: 1444780800,
      BB: 1449014400,
      CB: 1453248000,
      DB: 1456963200,
      EB: 1460592000,
      FB: 1464134400,
      GB: 1469059200,
      HB: 1472601600,
      IB: 1476230400,
      JB: 1480550400,
      KB: 1485302400,
      LB: 1489017600,
      MB: 1492560000,
      hB: 1496707200,
      NB: 1500940800,
      iB: 1504569600,
      OB: 1508198400,
      PB: 1512518400,
      U: 1516752000,
      QB: 1520294400,
      RB: 1523923200,
      SB: 1527552000,
      TB: 1532390400,
      UB: 1536019200,
      VB: 1539648000,
      WB: 1543968000,
      XB: 1548720000,
      YB: 1552348800,
      ZB: 1555977600,
      aB: 1559606400,
      bB: 1564444800,
      cB: 1568073600,
      dB: 1571702400,
      P: 1575936000,
      Q: 1580860800,
      R: 1586304000,
      S: 1589846400,
      V: 1594684800,
      W: 1598313600,
      X: 1601942400,
      Y: 1605571200,
      Z: 1611014400,
      a: 1614556800,
      b: 1618272000,
      c: 1621987200,
      d: 1626739200,
      e: 1630368000,
      f: 1632268800,
      g: 1634601600,
      T: 1637020800,
      h: 1641340800,
      H: 1643673600,
      kB: null,
      vB: null,
      wB: null
    }
  },
  E: {
    A: {
      I: 0,
      i: 0.004257,
      J: 0.004656,
      D: 0.004465,
      E: 0.004356,
      F: 0.004891,
      A: 0.004425,
      B: 0.004318,
      C: 0.008514,
      K: 0.055341,
      L: 0.272448,
      G: 0.208593,
      xB: 0,
      lB: 0.008692,
      yB: 0.012771,
      zB: 0.00456,
      "0B": 0.004283,
      "1B": 0.021285,
      mB: 0.012771,
      eB: 0.034056,
      fB: 0.068112,
      "2B": 0.498069,
      "3B": 1.05574,
      "4B": 0.974853,
      nB: 0.855657,
      oB: 0,
      "5B": 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "xB", "lB", "I", "i", "yB", "J", "zB", "D", "0B", "E", "F", "1B", "A", "mB", "B", "eB", "C", "fB", "K", "2B", "L", "3B", "G", "4B", "nB", "oB", "5B", ""],
    E: "Safari",
    F: {
      xB: 1205798400,
      lB: 1226534400,
      I: 1244419200,
      i: 1275868800,
      yB: 1311120000,
      J: 1343174400,
      zB: 1382400000,
      D: 1382400000,
      "0B": 1410998400,
      E: 1413417600,
      F: 1443657600,
      "1B": 1458518400,
      A: 1474329600,
      mB: 1490572800,
      B: 1505779200,
      eB: 1522281600,
      C: 1537142400,
      fB: 1553472000,
      K: 1568851200,
      "2B": 1585008000,
      L: 1600214400,
      "3B": 1619395200,
      G: 1632096000,
      "4B": 1635292800,
      nB: 1639353600,
      oB: null,
      "5B": null
    }
  },
  F: {
    A: {
      "0": 0.004257,
      "1": 0.004283,
      "2": 0.004367,
      "3": 0.004534,
      "4": 0.004257,
      "5": 0.004227,
      "6": 0.004418,
      "7": 0.004257,
      "8": 0.004227,
      "9": 0.004725,
      F: 0.0082,
      B: 0.016581,
      C: 0.004317,
      G: 0.00685,
      M: 0.00685,
      N: 0.00685,
      O: 0.005014,
      j: 0.006015,
      k: 0.004879,
      l: 0.006597,
      m: 0.006597,
      n: 0.013434,
      o: 0.006702,
      p: 0.006015,
      q: 0.005595,
      r: 0.004393,
      s: 0.004257,
      t: 0.004879,
      u: 0.004879,
      v: 0.004257,
      w: 0.005152,
      x: 0.005014,
      y: 0.009758,
      z: 0.004879,
      AB: 0.008514,
      BB: 0.008942,
      CB: 0.004707,
      DB: 0.004827,
      EB: 0.004707,
      FB: 0.004707,
      GB: 0.004326,
      HB: 0.008922,
      IB: 0.014349,
      JB: 0.004425,
      KB: 0.00472,
      LB: 0.004425,
      MB: 0.004425,
      NB: 0.00472,
      OB: 0.004532,
      PB: 0.004566,
      U: 0.02283,
      QB: 0.00867,
      RB: 0.004656,
      SB: 0.004642,
      TB: 0.004318,
      UB: 0.00944,
      VB: 0.004293,
      WB: 0.004293,
      XB: 0.004298,
      YB: 0.096692,
      ZB: 0.004201,
      aB: 0.004141,
      bB: 0.004257,
      cB: 0.004318,
      dB: 0.004356,
      P: 0.004257,
      Q: 0.008514,
      R: 0.008514,
      jB: 1.11533,
      S: 0.017028,
      "6B": 0.00685,
      "7B": 0.004257,
      "8B": 0.008392,
      "9B": 0.004706,
      eB: 0.006229,
      pB: 0.004879,
      AC: 0.008786,
      fB: 0.00472
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "F", "6B", "7B", "8B", "9B", "B", "eB", "pB", "AC", "C", "fB", "G", "M", "N", "O", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "AB", "BB", "CB", "DB", "EB", "FB", "GB", "HB", "IB", "JB", "KB", "LB", "MB", "NB", "OB", "PB", "U", "QB", "RB", "SB", "TB", "UB", "VB", "WB", "XB", "YB", "ZB", "aB", "bB", "cB", "dB", "P", "Q", "R", "jB", "S", "", "", ""],
    E: "Opera",
    F: {
      "0": 1457308800,
      "1": 1462320000,
      "2": 1465344000,
      "3": 1470096000,
      "4": 1474329600,
      "5": 1477267200,
      "6": 1481587200,
      "7": 1486425600,
      "8": 1490054400,
      "9": 1494374400,
      F: 1150761600,
      "6B": 1223424000,
      "7B": 1251763200,
      "8B": 1267488000,
      "9B": 1277942400,
      B: 1292457600,
      eB: 1302566400,
      pB: 1309219200,
      AC: 1323129600,
      C: 1323129600,
      fB: 1352073600,
      G: 1372723200,
      M: 1377561600,
      N: 1381104000,
      O: 1386288000,
      j: 1390867200,
      k: 1393891200,
      l: 1399334400,
      m: 1401753600,
      n: 1405987200,
      o: 1409616000,
      p: 1413331200,
      q: 1417132800,
      r: 1422316800,
      s: 1425945600,
      t: 1430179200,
      u: 1433808000,
      v: 1438646400,
      w: 1442448000,
      x: 1445904000,
      y: 1449100800,
      z: 1454371200,
      AB: 1498003200,
      BB: 1502236800,
      CB: 1506470400,
      DB: 1510099200,
      EB: 1515024000,
      FB: 1517961600,
      GB: 1521676800,
      HB: 1525910400,
      IB: 1530144000,
      JB: 1534982400,
      KB: 1537833600,
      LB: 1543363200,
      MB: 1548201600,
      NB: 1554768000,
      OB: 1561593600,
      PB: 1566259200,
      U: 1570406400,
      QB: 1573689600,
      RB: 1578441600,
      SB: 1583971200,
      TB: 1587513600,
      UB: 1592956800,
      VB: 1595894400,
      WB: 1600128000,
      XB: 1603238400,
      YB: 1613520000,
      ZB: 1612224000,
      aB: 1616544000,
      bB: 1619568000,
      cB: 1623715200,
      dB: 1627948800,
      P: 1631577600,
      Q: 1633392000,
      R: 1635984000,
      jB: 1638403200,
      S: 1642550400
    },
    D: {
      F: "o",
      B: "o",
      C: "o",
      "6B": "o",
      "7B": "o",
      "8B": "o",
      "9B": "o",
      eB: "o",
      pB: "o",
      AC: "o",
      fB: "o"
    }
  },
  G: {
    A: {
      E: 0,
      lB: 0,
      BC: 0,
      qB: 0.00306914,
      CC: 0.00613828,
      DC: 0.0936087,
      EC: 0.0199494,
      FC: 0.00767285,
      GC: 0.0168803,
      HC: 0.0920741,
      IC: 0.0337605,
      JC: 0.108954,
      KC: 0.0705902,
      LC: 0.0414334,
      MC: 0.0398988,
      NC: 0.603086,
      OC: 0.0352951,
      PC: 0.0153457,
      QC: 0.0844013,
      RC: 0.277757,
      SC: 0.868566,
      TC: 3.53411,
      UC: 5.88661,
      nB: 3.49882,
      oB: 0.00153457
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "lB", "BC", "qB", "CC", "DC", "EC", "E", "FC", "GC", "HC", "IC", "JC", "KC", "LC", "MC", "NC", "OC", "PC", "QC", "RC", "SC", "TC", "UC", "nB", "oB", "", ""],
    E: "Safari on iOS",
    F: {
      lB: 1270252800,
      BC: 1283904000,
      qB: 1299628800,
      CC: 1331078400,
      DC: 1359331200,
      EC: 1394409600,
      E: 1410912000,
      FC: 1413763200,
      GC: 1442361600,
      HC: 1458518400,
      IC: 1473724800,
      JC: 1490572800,
      KC: 1505779200,
      LC: 1522281600,
      MC: 1537142400,
      NC: 1553472000,
      OC: 1568851200,
      PC: 1572220800,
      QC: 1580169600,
      RC: 1585008000,
      SC: 1600214400,
      TC: 1619395200,
      UC: 1632096000,
      nB: 1639353600,
      oB: null
    }
  },
  H: {
    A: {
      VC: 1.02761
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "VC", "", "", ""],
    E: "Opera Mini",
    F: {
      VC: 1426464000
    }
  },
  I: {
    A: {
      gB: 0,
      I: 0.0323696,
      H: 0,
      WC: 0,
      XC: 0,
      YC: 0,
      ZC: 0.0129479,
      qB: 0.0582653,
      aC: 0,
      bC: 0.252483
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "WC", "XC", "YC", "gB", "I", "ZC", "qB", "aC", "bC", "H", "", "", ""],
    E: "Android Browser",
    F: {
      WC: 1256515200,
      XC: 1274313600,
      YC: 1291593600,
      gB: 1298332800,
      I: 1318896000,
      ZC: 1341792000,
      qB: 1374624000,
      aC: 1386547200,
      bC: 1401667200,
      H: 1643673600
    }
  },
  J: {
    A: {
      D: 0,
      A: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "D", "A", "", "", ""],
    E: "Blackberry Browser",
    F: {
      D: 1325376000,
      A: 1359504000
    }
  },
  K: {
    A: {
      A: 0,
      B: 0,
      C: 0,
      U: 0.0111391,
      eB: 0,
      pB: 0,
      fB: 0
    },
    B: "o",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "eB", "pB", "C", "fB", "U", "", "", ""],
    E: "Opera Mobile",
    F: {
      A: 1287100800,
      B: 1300752000,
      eB: 1314835200,
      pB: 1318291200,
      C: 1330300800,
      fB: 1349740800,
      U: 1613433600
    },
    D: {
      U: "webkit"
    }
  },
  L: {
    A: {
      H: 36.982
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "H", "", "", ""],
    E: "Chrome for Android",
    F: {
      H: 1643673600
    }
  },
  M: {
    A: {
      T: 0.292893
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "T", "", "", ""],
    E: "Firefox for Android",
    F: {
      T: 1641859200
    }
  },
  N: {
    A: {
      A: 0.0115934,
      B: 0.022664
    },
    B: "ms",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "A", "B", "", "", ""],
    E: "IE Mobile",
    F: {
      A: 1340150400,
      B: 1353456000
    }
  },
  O: {
    A: {
      cC: 0.901651
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "cC", "", "", ""],
    E: "UC Browser for Android",
    F: {
      cC: 1471392000
    },
    D: {
      cC: "webkit"
    }
  },
  P: {
    A: {
      I: 0.229958,
      dC: 0.0103543,
      eC: 0.010304,
      fC: 0.0627159,
      gC: 0.0103584,
      hC: 0.0209053,
      mB: 0.0105043,
      iC: 0.0836213,
      jC: 0.031358,
      kC: 0.114979,
      lC: 0.125432,
      mC: 0.188148,
      nC: 2.1637
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "I", "dC", "eC", "fC", "gC", "hC", "mB", "iC", "jC", "kC", "lC", "mC", "nC", "", "", ""],
    E: "Samsung Internet",
    F: {
      I: 1461024000,
      dC: 1481846400,
      eC: 1509408000,
      fC: 1528329600,
      gC: 1546128000,
      hC: 1554163200,
      mB: 1567900800,
      iC: 1582588800,
      jC: 1593475200,
      kC: 1605657600,
      lC: 1618531200,
      mC: 1629072000,
      nC: 1640736000
    }
  },
  Q: {
    A: {
      oC: 0.17229
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "oC", "", "", ""],
    E: "QQ Browser",
    F: {
      oC: 1589846400
    }
  },
  R: {
    A: {
      pC: 0
    },
    B: "webkit",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "pC", "", "", ""],
    E: "Baidu Browser",
    F: {
      pC: 1491004800
    }
  },
  S: {
    A: {
      qC: 0.080402
    },
    B: "moz",
    C: ["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "qC", "", "", ""],
    E: "KaiOS Browser",
    F: {
      qC: 1527811200
    }
  }
};
export default exports;